import * as React from "react"
import { Link } from "gatsby"
import { GrLinkedin, GrTwitter, GrGithub } from "react-icons/gr"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading margin">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <h3 className="margin">
        <Link className="header-link-home" to="/">
          {title}
        </Link>
      </h3>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main className="margin">{children}</main>
      <footer className="global-footer">
        <div className="footer-content">
          © {new Date().getFullYear()}, Marcos Zhu Lau todos los derechos reservados.
          <ul className="social">
            <li><a href="https://www.linkedin.com/in/marcos-zhu-lau" aria-label="Visita mi Linkedin"><GrLinkedin /></a></li>
            <li><a href="https://twitter.com/ZhumL" aria-label="Visita mi Twitter"><GrTwitter /></a></li>
            <li><a href="https://github.com/zhumarcos" aria-label="Visita mi Github"><GrGithub /></a></li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Layout
